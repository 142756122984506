import '@/src/styles/main.css'

// import galite from 'ga-lite'
import picoapp from 'picoapp'
import rola from 'rola'
// import film from '@/src/js/film.js'
import paralless from 'paralless'

const parallax = paralless()

window.feature = {
  touch: !!(("ontouchstart" in window) || window.navigator && window.navigator.msPointerEnabled && window.MSGesture || window.DocumentTouch && document instanceof DocumentTouch)
}

const animations = rola()
const app = picoapp({
  // time: require('@/src/lib/time.js').default
  // film
})

/**
 * init
 */
animations()
parallax()
app.mount()

// galite('create', 'UA-96992953-1', 'auto')
// galite('send', 'pageview')

